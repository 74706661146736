import React, { useState, useEffect } from "react";
import ScrollToTop from "react-scroll-to-top";
import { NavLink } from "react-router-dom";

export default function Footer() {
  const Site_Url = "https://egazetteharyana.com/";
  const BASE_URL = Site_Url + "admin/API/";

  //website Setting API
  const [webSetting, setwebSetting] = useState([]);
  useEffect(() => {
    const webSettingData = async () => {
      const response = await fetch(`${BASE_URL}website_settingAPI.php`);
      const jsonData = await response.json();
      setwebSetting(jsonData);
    };
    webSettingData();
  }, [BASE_URL]);

  //service API
  const [servicedata, setservicedata] = useState([]);
  useEffect(() => {
    const AllServices = async () => {
      const response = await fetch(`${BASE_URL}serviceAPI.php`);
      const jsonData = await response.json();
      setservicedata(jsonData);
    };
    AllServices();
  }, [BASE_URL]);

  return (
    <>
      <footer className="footer_section footer">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-12">
                <div className="single-widget list">
                  <NavLink className="navbar-brand brand" to="/">
                    egaze<span className="logo_text_name">tte</span>
                    <p className="brand_text_area">
                      <span className="logo_text_change">har</span>
                      yana
                    </p>
                  </NavLink>
                  <ul className="list">
                    <li>
                      <i className="fa fa-map-marker"></i>
                      {webSetting.address}
                    </li>
                    <li>
                      <i className="fa fa-phone"></i>
                      <NavLink to={`tel:${webSetting.number}`}>
                        {" "}
                        {webSetting.number}
                      </NavLink>
                    </li>
                    <li>
                      <i className="fa fa-envelope"></i>
                      <NavLink to={`mailto:${webSetting.email}`}>
                        {webSetting.email}
                      </NavLink>
                    </li>
                    {/* <li>
                      <i className="fa fa-globe"></i>
                      <NavLink to={webSetting.baseURl}>
                      {webSetting.baseURl}
                      </NavLink>
                    </li> */}
                  </ul>
                  <ul className="social">
                    <li>
                      <NavLink to={webSetting.facebook}>
                        <i className="fa fa-facebook-f"></i>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={webSetting.twitter}>
                        <i className="fa fa-twitter"></i>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={webSetting.linkedIn}>
                        <i className="fa fa-linkedin"></i>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={webSetting.instagram}>
                        <i className="fa fa-instagram"></i>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={webSetting.youtube}>
                        <i className="fa fa-youtube"></i>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-7 col-12">
                <div className="single-widget useful-links">
                  <h2>Our Services</h2>
                  <ul>
                    {servicedata.map((value, index) => (
                      <li key={index}>
                        <i className="fa fa-dot-circle-o"></i>
                        <NavLink title={value.name} to="/services">
                          {value.name}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-12">
                <div className="single-widget list">
                  <h2>Quick Links</h2>
                  <ul>
                    <li>
                      <i className="fa fa-dot-circle-o"></i>
                      <NavLink to="/">Home </NavLink>
                    </li>
                    <li>
                      <i className="fa fa-dot-circle-o"></i>
                      <NavLink to="/about">About Us </NavLink>
                    </li>
                    <li>
                      <i className="fa fa-dot-circle-o"></i>
                      <NavLink to="/services">Services </NavLink>
                    </li>
                    <li>
                      <i className="fa fa-dot-circle-o"></i>
                      <NavLink to="/blog/en">Blogs </NavLink>
                    </li>
                    <li>
                      <i className="fa fa-dot-circle-o"></i>
                      <NavLink to="/contact">Contact Us</NavLink>
                    </li>
                    <li>
                      <i className="fa fa-dot-circle-o"></i>
                      <NavLink to="/terms-conditions">
                        Terms & Conditions
                      </NavLink>
                    </li>
                    <li>
                      <i className="fa fa-dot-circle-o"></i>
                      <NavLink to="/privacy-policy">Privacy Policy</NavLink>
                    </li>
                    <li>
                      <i className="fa fa-dot-circle-o"></i>
                      <NavLink to="/refund-policy">Refund Policy</NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer_bottom">
          <div className="container">
            <div className="row text-center">
              <div className="col-lg-12 col-md-12">
                <div className="left_footer">
                  <p>{webSetting.copyright}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <a
        href={`https://wa.me/${webSetting.whatsaap}?text=Hi, I would like to speak to a Name Change Service`}
        className="wa-float-img-circle"
        target="_blank"
        rel="noreferrer"
      >
        <i className="fa fa-whatsapp" aria-hidden="true"></i>
      </a>

      <a
        href={`tel:${webSetting.number}`}
        className="call-float-img-circle"
        target="_blank"
        rel="noreferrer"
      >
        <i className="fa fa-phone" aria-hidden="true"></i>
      </a>

      <ScrollToTop smooth="true" />
    </>
  );
}
