import React, { useState, useEffect } from "react";
import DataContent from "./DataContent";
import { Helmet } from "react-helmet";

export default function RefundPolicy() {
 const Site_Url = "https://egazetteharyana.com/";
 const BASE_URL = Site_Url + "admin/API/";

 //website Setting API
 const [webSetting, setwebSetting] = useState([]);
 useEffect(() => {
   const webSettingData = async () => {
     const response = await fetch(`${BASE_URL}website_settingAPI.php`);
     const jsonData = await response.json();
     setwebSetting(jsonData);
   };
   webSettingData();
 }, [BASE_URL]);
  
  return (
    <>
      <Helmet>
        <title>Refund Policy</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <DataContent title="Refund Policy" desc={webSetting.refund_policy_desc} />
    </>
  );
}
